import React from 'react';
import * as Scroll from 'react-scroll';
import { Link, Element , Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll';
import { Link as Link1 } from "gatsby"
 
import style from './service.module.css';


class Service extends React.Component {
	state= {
		nav: style.navWrapper
	}

	componentDidMount() {
		Events.scrollEvent.register('begin', function(to, element) {
			//console.log("1", to, element);
		});

		Events.scrollEvent.register('end', function(to, element) {
			//console.log("4", element);
		});

		window.addEventListener('scroll', this.handleScroll);

		scrollSpy.update();

	}

	handleScroll= e => {
		if(window.scrollY > 100) {
			this.setState({nav: style.navWrapperSticky})
		}
		else if(window.scrollY < 50) {
			this.setState({nav: style.navWrapper});
		}
	}
	componentWillUnmount() {
		Events.scrollEvent.remove('begin');
		Events.scrollEvent.remove('end');
	}

	handleSetActive= e => {
		//console.log(e, this.state.nav)
	}

	getFirstLetter(w){
		let matches = w.match(/\b(\w)/g);
		return matches.join('').toUpperCase();
	}

	render() {
		const {data} = this.props
		const {nav} = this.state;
		return (
			<section className={style.services}>
				<h3>Treatment in Reviving Wholeness</h3>
				<div className={style.container}>
					<div className={style.itemsContainer}>
					{
						data.map((item, index)=>{
							return(
								<Element name={index.toString()} className={`${style.serviceItem} element`} key={`service_${index}`}>
									<div className={style.itemWrapper}>
										<div className={style.serviceContent}>
											<div className={style.serviceContentBg}/>
											<div className={style.serviceContentWrap}>
												<h4>
													<Link1 to={`/treatment-services/${item.slug}`}>{item.name}</Link1>
												</h4>
												<p>
													{item.excerpt.length > 200 ? item.excerpt.substring(0,200) : item.excerpt} {item.excerpt.length > 200 ? '...' : ''}
												</p>
												<span className={style.sReadMore}>
													Read more
												</span>
												<div className={style.childContentWrapper}>
													<ul>
														{
															item.services.map((i, ix)=>{
																return (
																	<li key={ix}>
																		<Link1 to={`/treatment-services/${item.slug}/${i.slug}`}>
																			{i.name}
																		</Link1>
																	</li>
																)
															})
														}
													</ul>
												</div>
											</div>
										</div>
										<div className={style.serviceImage}>
											<img src={item.image} alt=""/>
										</div>
									</div>
								</Element>
							)
						})
					}
					</div>
					<div className={style.navItemsContainer}>
						<div className={`${nav} ${style.serviceFeature}`}>
							{
								data.map((item, index)=>{
									return(
										<div className={style.serviceFeatureItem} key={`path_${index}`}>
											<Link activeClass={style.active} to={index.toString()} spy={true} smooth={true} offset={-50} duration={500} onSetActive={this.handleSetActive}>
												{ item.name.length > 20 ? this.getFirstLetter(item.name) : item.name} <span/>
											</Link>
										</div>
									)
								})
							}
						</div>
					</div>
				</div>
			</section>
		)
	}

}


export default Service
