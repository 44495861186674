import React from 'react';
import {graphql, useStaticQuery} from "gatsby"

import Service from './service'

const Serviceindex = () => {
	const query = useStaticQuery(graphql`
		query {
			allStrapiServiceCategories(sort: {order: DESC, fields: created_at}) {
			    edges {
			      node {
			        name
			        excerpt
			        slug
			        feature_image {
			          childImageSharp {
			            fluid {
			              src
			            }
			          }
			        }
			        services{
			        	name
			        	slug
			        }
			      }
			    }
			}
		}
	`)
	let d = [];
	query.allStrapiServiceCategories.edges.forEach(({node})=>{
		node.image = node.feature_image ? node.feature_image.childImageSharp.fluid.src : null
		d.push(node);
	})
	return(
		<>
			<Service data={d}/>
		</>
	)
}

export default Serviceindex
