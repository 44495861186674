import React from "react"
import SEO from "../components/seo"
import Layout from '../components/layout'
import Services from '../components/services/index'

const ServicesPage = () => {
	return(
		<Layout>
		  <SEO title="Treatment Services" />
		  <Services/>
		</Layout>
	)
}

export default ServicesPage
